import DetailWebView, {GameWebView} from '@/common-pages/webview';
import Login, {SetPassword, SingUp} from '@/common-pages/login';
import {BasicObject, NavigatorScreenProps} from '@/types';
import Result from '@/common-pages/result';
import PaidSuccess from '@/common-pages/paid-success';
import Recharge from '@/common-pages/recharge';
import Withdraw from '@/common-pages/withdraw';
import Vip from '@/common-pages/vip/vip';
import Rebate from '@/common-pages/rebate';
import Bets from '@/common-pages/bets';
import BetsDetail from '@/common-pages/bets-detail';
import AddBank from '@/common-pages/add-bank';
import Transactions from '@/common-pages/transaction';
import Transfer from '@/common-pages/transfer';
import NotificationPage from '@/common-pages/notification';
import NotificationDetail from '@/common-pages/notification/notification-detail';
import Profile from '@/common-pages/profile';
import BindPhone from '@/common-pages/profile/bindPhone';
import {
  RechargeRecords,
  WithdrawRecords,
  TransferRecords,
} from '@/common-pages/records';
import {
  ProxyRules,
  ProxyCommissionDetail,
  ProxyCommissionDetailUser,
  ProxyTeamReport,
  ProxyTeamReportSearch,
  ProxyNewUser,
} from '@/common-pages/proxy';
import Invitation, {
  InvitationApply,
  InvitationRecord,
  InvitationRule,
} from '@/common-pages/invitation';
import ColorGame from '@/common-pages/color';
import DiceGame from '@/common-pages/dice/dice';
import MixLottery from '@/common-pages/mix-lottery/mix-lottery';
import BetsShard from '@/common-pages/betsShard';
import MixLotteryDetail from '@/common-pages/result-detail/sate-lottery/mix-lottery-detail';
import BetBy from '@/pages/betby/index';
import Promotion from '@/common-pages/promotion';
type Route = {
  name: string;
  component: (props: NavigatorScreenProps & BasicObject) => React.JSX.Element;
  link: string;
  headerShown?: boolean;
};

const commonRoutes: Route[] = [
  {
    name: 'Promotion',
    component: Promotion,
    link: 'promotion',
  },
  {
    name: 'BetsShard',
    component: BetsShard,
    link: 'bets-shard',
  },
  {
    name: 'SingUp',
    component: SingUp,
    link: 'sing-up',
  },
  {
    name: 'Login',
    component: Login,
    link: 'login',
  },
  {
    name: 'SetPassword',
    component: SetPassword,
    link: 'set-password',
  },
  {
    name: 'Result',
    component: Result,
    link: 'result',
  },
  {
    name: 'Recharge',
    component: Recharge,
    link: 'recharge',
  },
  {
    name: 'Withdraw',
    component: Withdraw,
    link: 'withdraw',
  },
  {
    name: 'AddBank',
    component: AddBank,
    link: 'add-bank',
  },
  {
    name: 'Vip',
    component: Vip,
    link: 'vip',
  },
  {
    name: 'PaidSuccess',
    component: PaidSuccess,
    link: 'paid-success',
  },
  {
    name: 'Rebate',
    component: Rebate,
    link: 'rebate',
  },
  {
    name: 'Bets',
    component: Bets,
    link: 'bets',
  },
  {
    name: 'BetsDetail',
    component: BetsDetail,
    link: 'bets-detail',
  },
  {
    name: 'Transactions',
    component: Transactions,
    link: 'transactions',
  },
  {
    name: 'Transfer',
    component: Transfer,
    link: 'transfer',
  },
  {
    name: 'RechargeRecords',
    component: RechargeRecords,
    link: 'recharge-records',
  },
  {
    name: 'WithdrawRecords',
    component: WithdrawRecords,
    link: 'withdraw-records',
  },
  {
    name: 'TransferRecords',
    component: TransferRecords,
    link: 'transfer-records',
  },
  {
    name: 'GameWebView',
    component: GameWebView,
    link: 'game-webview',
  },
  {
    name: 'WebView',
    component: DetailWebView,
    link: 'webview',
  },
  {
    name: 'Notification',
    component: NotificationPage,
    link: 'notification',
  },
  {
    name: 'NotificationDetail',
    component: NotificationDetail,
    link: 'notification-detail',
  },
  {
    name: 'ProxyRules',
    component: ProxyRules,
    link: 'proxy-rules',
  },
  {
    name: 'ProxyCommissionDetail',
    component: ProxyCommissionDetail,
    link: 'proxy-commission-detail',
  },
  {
    name: 'ProxyCommissionDetailUser',
    component: ProxyCommissionDetailUser,
    link: 'proxy-commission-detail-user',
  },
  {
    name: 'ProxyTeamReport',
    component: ProxyTeamReport,
    link: 'proxy-team-report',
  },
  {
    name: 'ProxyTeamReportSearch',
    component: ProxyTeamReportSearch,
    link: 'proxy-team-report-search',
  },
  {
    name: 'ProxyNewUser',
    component: ProxyNewUser,
    link: 'proxy-new-user',
  },
  {
    name: 'Invitation',
    component: Invitation,
    link: 'invitation',
  },
  {
    name: 'InvitationApply',
    component: InvitationApply,
    link: 'invitation-apply',
  },
  {
    name: 'InvitationRecord',
    component: InvitationRecord,
    link: 'invitation-record',
  },
  {
    name: 'InvitationRule',
    component: InvitationRule,
    link: 'invitation-rule',
  },
  {name: 'Dice', component: DiceGame, link: 'dice'},
  {
    name: 'Color',
    component: ColorGame,
    link: 'color',
  },
  {
    name: 'Profile',
    component: Profile,
    link: 'profile',
  },
  {
    name: 'BindPhone',
    component: BindPhone,
    link: 'bind-phone', // 绑定手机号页面
  },
  {name: 'MixLottery', component: MixLottery, link: 'mix-lottery'},
  {
    name: 'MixLotteryDetail',
    component: MixLotteryDetail,
    link: 'mix-lottery-detail',
  },
];

const customerRoutes: Route[] = [
  // 这里放各个盘需要的额外路由配置
  // 为了不破坏顶部的引用结构,请使用require的方式引入
  {
    name: 'PromotionDetail',
    component: require('@/common-pages/promotion').PromotionDetail,
    link: 'promotion-detail',
  },
  {
    name: 'LiveCasino',
    component: require('@/pages/live-casino').default,
    link: 'live-casino',
  },
  {
    name: 'Scratch',
    component: require('@/pages/scratch').default,
    link: 'scratch',
  },
  {
    name: 'Lottery',
    component: require('@/common-pages/lottery').default,
    link: 'lottery',
  },
  {
    name: 'Satta',
    component: require('@/common-pages/satta/game-satta').default,
    link: 'satta',
  },
  {
    name: 'ScratchResultDetail',
    component: require('@/common-pages/result-detail/scratch/scratch').default,
    link: 'scratch-result-detail',
  },
  {
    name: 'ProxyHome',
    component: require('@/common-pages/proxy/proxy-home').default,
    link: 'proxy-home',
  },
  {
    name: 'BetBy',
    component: BetBy,
    link: 'BetBy',
  },
  // {
  //   name: 'Chat',
  //   component: require('@/common-pages/im/im-webview').default,
  //   link: 'chat',
  // },
  {
    name: 'PromotionsV2',
    component: require('@/pages/promotions').default,
    link: 'promotionsv2',
  },
  {
    name: 'PromotionsV2History',
    component: require('@/pages/promotions/history').default,
    link: 'promotionsv2-history',
  },
  {
    name: 'PromotionsV2Common',
    component: require('@/pages/promotions/detail/common').default,
    link: 'promotionsv2-common',
  },
  {
    name: 'PromotionsV2Cumulate',
    component: require('@/pages/promotions/detail/cumulate').default,
    link: 'promotionsv2-cumulate',
  },
  {
    name: 'PromotionsV2RechargeRebate',
    component: require('@/pages/promotions/detail/recharge-rebate').default,
    link: 'promotionsv2-recharge-rebate',
  },
];

const routes: Route[] = [...commonRoutes, ...customerRoutes];

export default routes;
